// Gray Scale
$gray-50: #FDFDFD;
$gray-100: #F8F8F8;
$gray-200: #E6E6E6;
$gray-300: #D5D5D5;
$gray-400: #B1B1B1;
$gray-500: #909090;
$gray-600: #6D6D6D;
$gray-700: #464646;
$gray-800: #222222;
$gray-900: #000000;

// Success – Green
$success-50: #E8F5E9;
$success-100: #DDEFDB;
$success-200: #AAD8A6;
$success-300: #87C883;
$success-400: #61B761;
$success-500: #4CAF50;
$success-600: #459C48;
$success-700: #387739;
$success-800: #2B542B;
$success-900: #1E341D;

// Error – Red
$error-50: #FFEEEB;
$error-100: #FFDCD7;
$error-200: #FFA89D;
$error-300: #FD8378;
$error-400: #F55B55;
$error-500: #EF4444;
$error-600: #D53F3E;
$error-700: #A23431;
$error-800: #722825;
$error-900: #451C19;

// Warning – Orange
$warning-50: #FFF5EB;
$warning-100: #FFEAD7;
$warning-200: #FFCC9D;
$warning-300: #FFB777;
$warning-400: #FFA34F;
$warning-500: #FF993A;
$warning-600: #E38935;
$warning-700: #AC692C;
$warning-800: #794B22;
$warning-900: #492E18;

// etrainu – Jade
$jade-50: #E5F3EE;
$jade-100: #ACE2D3;
$jade-200: #81D3BB;
$jade-300: #56C4A4;
$jade-400: #2BB58D;
$jade-500: #00A676;
$jade-600: #008A62;
$jade-700: #006E4E;
$jade-800: #00523A;
$jade-900: #003626;

// etrainu – Tuna
$tuna: #2C2D34;
