/* replace color properties - ([\n\r]+  color: #[^*.]+;) */
@font-face {
  font-family: 'etrainu-ui-icons';
  src:  url('~assets/fonts/etrainu-ui-icons/etrainu-ui-icons.eot?');
  src:  url('~assets/fonts/etrainu-ui-icons/etrainu-ui-icons.eot?#iefix') format('embedded-opentype'),
  url('~assets/fonts/etrainu-ui-icons/etrainu-ui-icons.ttf?') format('truetype'),
  url('~assets/fonts/etrainu-ui-icons/etrainu-ui-icons.woff?') format('woff'),
  url('~assets/fonts/etrainu-ui-icons/etrainu-ui-icons.svg?#etrainu-ui-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

//[class^="icon" ]:not(.otb-icon, .otb-icon-bold, .otb-icon-thin), [class*=" icon"]:not(.otb-icon, .otb-icon-bold, .otb-icon-thin) {
.etu-icon:not([class^="etrainu-icon"]),.icon:not([class^="etrainu-icon"]){
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'etrainu-ui-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: inherit;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.icon-data-checker-flag-1:before {
    content: "\e941";
  }

  &.icon-login-reg:before {
    content: "\e93e";
  }

  &.icon-logout-reg:before {
    content: "\e93f";
  }

  &.icon-login-thin:before {
    content: "\e90e";
  }

  &.icon-logout-thin:before {
    content: "\e93a";
  }

  &.icon-buoy-thin:before {
    content: "\e93b";
  }

  &.icon-help-circle-thin:before {
    content: "\e93c";
  }

  &.icon-events-thin:before {
    content: "\e93d";
  }

  &.icon-home-thin:before {
    content: "\e938";
  }

  &.icon-dashboard-thin:before {
    content: "\e939";
  }

  &.icon-logout:before {
    content: "\e90d";
  }

  &.icon-special-needs:before {
    content: "\e900";
  }

  &.icon-course:before {
    content: "\e901";
  }

  &.icon-hash:before {
    content: "\e902";
  }

  &.icon-id-card:before {
    content: "\e903";
  }

  &.icon-data-upload:before {
    content: "\e904";
  }

  &.icon-file-notes-alert:before {
    content: "\e905";
  }

  &.icon-file-view:before {
    content: "\e906";
  }

  &.icon-file-notes-add:before {
    content: "\e907";
  }

  &.icon-file-add:before {
    content: "\e908";
  }

  &.icon-file-check:before {
    content: "\e909";
  }

  &.icon-file-remove:before {
    content: "\e90a";
  }

  &.icon-file-copy:before {
    content: "\e90b";
  }

  &.icon-certificate:before {
    content: "\e90c";
  }

  &.icon-file-upload:before {
    content: "\e90f";
  }

  &.icon-stopwatch:before {
    content: "\e910";
  }

  &.icon-close:before {
    content: "\e911";
  }

  &.icon-calendar-pencil:before {
    content: "\e912";
  }

  &.icon-time:before {
    content: "\e913";
  }

  &.icon-add-circle-filled:before {
    content: "\e914";
  }

  &.icon-add-circle:before {
    content: "\e915";
  }

  &.icon-pencil:before {
    content: "\e916";
  }

  &.icon-file-audio:before {
    content: "\e917";
  }

  &.icon-file-image:before {
    content: "\e918";
  }

  &.icon-file-video:before {
    content: "\e919";
  }

  &.icon-file-document:before {
    content: "\e91a";
  }

  &.icon-data-transfer:before {
    content: "\e91b";
  }

  &.icon-alert-circle-filled:before {
    content: "\e91c";
  }

  &.icon-alert-triangle-filled:before {
    content: "\e91d";
  }

  &.icon-alert-circle:before {
    content: "\e91e";
  }

  &.icon-alert-triangle:before {
    content: "\e91f";
  }

  &.icon-check:before {
    content: "\e920";
  }

  &.icon-plus:before {
    content: "\e921";
  }

  &.icon-chevron-left:before {
    content: "\e922";
  }

  &.icon-chevron-up:before {
    content: "\e923";
  }

  &.icon-chevron-right:before {
    content: "\e924";
  }

  &.icon-chevron-down:before {
    content: "\e925";
  }

  &.icon-show-more:before {
    content: "\e926";
  }

  &.icon-arrow-down:before {
    content: "\e927";
  }

  &.icon-arrow-up:before {
    content: "\e928";
  }

  &.icon-complete:before {
    content: "\e929";
  }

  &.icon-call:before {
    content: "\e92a";
  }

  &.icon-camera:before {
    content: "\e92b";
  }

  &.icon-email-sm:before {
    content: "\e92c";
  }

  &.icon-sms:before {
    content: "\e92d";
  }

  &.icon-map-sm:before {
    content: "\e92e";
  }

  &.icon-calendar:before {
    content: "\e92f";
  }

  &.icon-buoy-nova:before {
    content: "\e930";
  }

  &.icon-menu-right:before {
    content: "\e931";
  }

  &.icon-menu-left:before {
    content: "\e932";
  }

  &.icon-help:before {
    content: "\e933";
  }

  &.icon-assessments:before {
    content: "\e934";
  }

  &.icon-events:before {
    content: "\e935";
  }

  &.icon-search:before {
    content: "\e936";
  }

  &.icon-dashboard:before {
    content: "\e937";
  }

  &.icon-arrow-up-down:before {
    content: "\e940";
  }
}
