$icomoon-bold-font-family: "etrainu-icon-bold" !default;
$icomoon-bold-font-path: "assets/fonts/etrainu-icons-bold" !default;

$icon-safety-float: unquote('"\\e93c"');
$icon-training-library: unquote('"\\e93d"');
$icon-book-close-bookmark: unquote('"\\e93e"');
$icon-dashboard: unquote('"\\e93f"');
$icon-arrow-right: unquote('"\\e940"');
$icon-add-alarm: unquote('"\\e941"');
$icon-add-box: unquote('"\\e942"');
$icon-add: unquote('"\\e943"');
$icon-alert-triangle: unquote('"\\e949"');
$icon-arrow-down: unquote('"\\e982"');
$icon-arrow-left: unquote('"\\e99c"');
$icon-arrow-up: unquote('"\\e99d"');
$icon-calendar: unquote('"\\e99e"');
$icon-check-box-alternate: unquote('"\\e99f"');
$icon-check-box-blank: unquote('"\\e9a0"');
$icon-check-box-outline-blank: unquote('"\\e9a1"');
$icon-check-box: unquote('"\\e9a2"');
$icon-chevron-down: unquote('"\\e9a3"');
$icon-chevron-left: unquote('"\\e9a4"');
$icon-chevron-right: unquote('"\\e9a5"');
$icon-chevron-up: unquote('"\\e9a6"');
$icon-clear-solid: unquote('"\\e9a7"');
$icon-clear: unquote('"\\e9a8"');
$icon-close: unquote('"\\e9a9"');
$icon-cloud-upload: unquote('"\\e9aa"');
$icon-comment: unquote('"\\e9ab"');
$icon-edit: unquote('"\\e9ac"');
$icon-email-add: unquote('"\\e9ad"');
$icon-email-body: unquote('"\\e9ae"');
$icon-email: unquote('"\\e9af"');
$icon-event-busy: unquote('"\\e9b0"');
$icon-event: unquote('"\\e9b1"');
$icon-filter: unquote('"\\e9b2"');
$icon-first-page: unquote('"\\e9b3"');
$icon-flag: unquote('"\\e9b4"');
$icon-home: unquote('"\\e9b5"');
$icon-keyboard-arrow-down: unquote('"\\e9b6"');
$icon-keyboard-arrow-left: unquote('"\\e9b7"');
$icon-keyboard-arrow-next: unquote('"\\e9b8"');
$icon-keyboard-arrow-previous: unquote('"\\e9b9"');
$icon-keyboard-arrow-right: unquote('"\\e9ba"');
$icon-keyboard-arrow-up: unquote('"\\e9bb"');
$icon-last-page: unquote('"\\e9bc"');
$icon-logout: unquote('"\\e9bd"');
$icon-maps: unquote('"\\e9be"');
$icon-menu: unquote('"\\e9bf"');
$icon-more-horizontal: unquote('"\\e9c0"');
$icon-more-vert: unquote('"\\e9c1"');
$icon-person-outline: unquote('"\\e9c2"');
$icon-phone: unquote('"\\e9c3"');
$icon-question-circle: unquote('"\\e9c4"');
$icon-refresh: unquote('"\\e9c5"');
$icon-school: unquote('"\\e9c6"');
$icon-search: unquote('"\\e9c7"');
$icon-send-email: unquote('"\\e9c8"');
$icon-settings-off: unquote('"\\e9c9"');
$icon-settings-on: unquote('"\\e9ca"');
$icon-sms: unquote('"\\e9cb"');
$icon-stopwatch: unquote('"\\e9cc"');
$icon-video-call: unquote('"\\e9cd"');
$icon-view: unquote('"\\e9ce"');
$icon-wifi-1m: unquote('"\\e9cf"');
$icon-wifi-5m: unquote('"\\e9d0"');
$icon-wifi-30s: unquote('"\\e9d1"');
$icon-wifi-off: unquote('"\\e9d2"');
$icon-wifi-refresh: unquote('"\\e9d3"');
$icon-wifi: unquote('"\\e9d4"');
$icon-validation-check: unquote('"\\e9d5"');
$icon-check-circle: unquote('"\\e900"');
$icon-no-entry: unquote('"\\e901"');


