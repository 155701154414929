@keyframes external {
  0% {
    stroke-dashoffset: 312;
    transform: rotate(70deg);
  }
  60% {
    stroke-dashoffset: -312;
  }
  100% {
    stroke-dashoffset: -312;
    transform: rotate(450deg);
  }
}
