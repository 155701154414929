$icomoon-thin-font-family: "etrainu-icon-thin" !default;
$icomoon-thin-font-path: "assets/fonts/etrainu-icons-thin" !default;

$icon-thin-single-man: "\e9de";
$icon-thin-lock: "\e9df";
$icon-thin-add-alarm: "\e907";
$icon-thin-add-box: "\e908";
$icon-thin-add: "\e909";
$icon-thin-alert-triangle: "\e90a";
$icon-thin-arrow-down: "\e90b";
$icon-thin-arrow-left: "\e90c";
$icon-thin-arrow-right: "\e90d";
$icon-thin-arrow-up: "\e914";
$icon-thin-calendar: "\e915";
$icon-thin-check-box-outline-blank: "\e916";
$icon-thin-check-box: "\e917";
$icon-thin-chevron-down: "\e918";
$icon-thin-chevron-left: "\e919";
$icon-thin-chevron-right: "\e91a";
$icon-thin-chevron-up: "\e90e";
$icon-thin-clear: "\e90f";
$icon-thin-close: "\e910";
$icon-thin-cloud-upload: "\e911";
$icon-thin-edit: "\e912";
$icon-thin-email-add: "\e913";
$icon-thin-email-body: "\e91b";
$icon-thin-email: "\e91c";
$icon-thin-event-busy: "\e91d";
$icon-thin-events-thin: "\e924";
$icon-thin-filter: "\e925";
$icon-thin-first-page: "\e926";
$icon-thin-flag: "\e927";
$icon-thin-help-circle-thin: "\e929";
$icon-thin-home-thin: "\e92a";
$icon-thin-last-page: "\e92b";
$icon-thin-logout: "\e91e";
$icon-thin-maps: "\e91f";
$icon-thin-menu: "\e920";
$icon-thin-more-horizontal: "\e921";
$icon-thin-more-vert: "\e922";
$icon-thin-person-outline: "\e923";
$icon-thin-phone: "\e92c";
$icon-thin-refresh: "\e92d";
$icon-thin-school: "\e92e";
$icon-thin-search: "\e935";
$icon-thin-settings-off: "\e936";
$icon-thin-settings-on: "\e937";
$icon-thin-sms: "\e938";
$icon-thin-video-call-alt: "\e939";
$icon-thin-video-call: "\e93a";
$icon-thin-view: "\e93b";
$icon-thin-wifi-1m: "\e92f";
$icon-thin-wifi-5m: "\e930";
$icon-thin-wifi-30s: "\e931";
$icon-thin-wifi-off: "\e932";
$icon-thin-wifi-refresh: "\e933";
$icon-thin-wifi: "\e934";

