@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('~#{$icomoon-font-path}/#{$icomoon-font-family}.eot?{version}');
  src:  url('~#{$icomoon-font-path}/#{$icomoon-font-family}.eot?{version}#iefix') format('embedded-opentype'),
    url('~#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?{version}') format('truetype'),
    url('~#{$icomoon-font-path}/#{$icomoon-font-family}.woff?{version}') format('woff'),
    url('~#{$icomoon-font-path}/#{$icomoon-font-family}.svg?{version}##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.etrainu-icon-regular {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-award-ribbon-star {
  &:before {
    content: $icon-award-ribbon-star;
  }
}
.icon-user-search {
  &:before {
    content: $icon-user-search;
  }
}
.icon-missing-data {
  &:before {
    content: $icon-missing-data;
  }
}
.icon-contract-diagonal {
  &:before {
    content: $icon-contract-diagonal;
  }
}
.icon-checkmark {
  &:before {
    content: $icon-checkmark;
  }
}
.icon-check-box-outline-blank {
  &:before {
    content: $icon-check-box-outline-blank;
  }
}
.icon-single-man-refresh {
  &:before {
    content: $icon-single-man-refresh;
  }
}
.icon-single-man {
  &:before {
    content: $icon-single-man;
  }
}
.icon-blood-drop {
  &:before {
    content: $icon-blood-drop;
  }
}
.icon-asterisk {
  &:before {
    content: $icon-asterisk;
  }
}
.icon-alarm-bell {
  &:before {
    content: $icon-alarm-bell;
  }
}
.icon-alert-circle {
  &:before {
    content: $icon-alert-circle;
  }
}
.icon-arrow-button-down {
  &:before {
    content: $icon-arrow-button-down;
  }
}
.icon-arrow-button-left {
  &:before {
    content: $icon-arrow-button-left;
  }
}
.icon-arrow-button-right {
  &:before {
    content: $icon-arrow-button-right;
  }
}
.icon-arrow-button-up {
  &:before {
    content: $icon-arrow-button-up;
  }
}
.icon-button-play {
  &:before {
    content: $icon-button-play;
  }
}
.icon-common-file-text {
  &:before {
    content: $icon-common-file-text;
  }
}
.icon-cursor-target {
  &:before {
    content: $icon-cursor-target;
  }
}
.icon-download-bottom {
  &:before {
    content: $icon-download-bottom;
  }
}
.icon-expand-diagonal {
  &:before {
    content: $icon-expand-diagonal;
  }
}
.icon-expand-horizontal {
  &:before {
    content: $icon-expand-horizontal;
  }
}
.icon-expand-vertical {
  &:before {
    content: $icon-expand-vertical;
  }
}
.icon-explosion-lines {
  &:before {
    content: $icon-explosion-lines;
  }
}
.icon-fantasy-cauldron {
  &:before {
    content: $icon-fantasy-cauldron;
  }
}
.icon-floppy-disk {
  &:before {
    content: $icon-floppy-disk;
  }
}
.icon-folder-close {
  &:before {
    content: $icon-folder-close;
  }
}
.icon-folder-open {
  &:before {
    content: $icon-folder-open;
  }
}
.icon-information-circle {
  &:before {
    content: $icon-information-circle;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
  }
}
.icon-subtract {
  &:before {
    content: $icon-subtract;
  }
}
.icon-zoom-in {
  &:before {
    content: $icon-zoom-in;
  }
}
.icon-zoom-out {
  &:before {
    content: $icon-zoom-out;
  }
}
.icon-add-alarm {
  &:before {
    content: $icon-add-alarm;
  }
}
.icon-bin {
  &:before {
    content: $icon-bin;
  }
}
.icon-check-circle {
  &:before {
    content: $icon-check-circle;
  }
}
.icon-delete-trash-restore {
  &:before {
    content: $icon-delete-trash-restore;
  }
}
.icon-house {
  &:before {
    content: $icon-house;
  }
}
.icon-note-book {
  &:before {
    content: $icon-note-book;
  }
}
.icon-passport-globe {
  &:before {
    content: $icon-passport-globe;
  }
}
.icon-shared-document {
  &:before {
    content: $icon-shared-document;
  }
}
.icon-partnership {
  &:before {
    content: $icon-partnership;
  }
}
.icon-graduate-hat {
  &:before {
    content: $icon-graduate-hat;
  }
}
.icon-user-switch {
  &:before {
    content: $icon-user-switch;
  }
}
.icon-image-file {
  &:before {
    content: $icon-image-file;
  }
}
.icon-hyperlink {
  &:before {
    content: $icon-hyperlink;
  }
}
.icon-login {
  &:before {
    content: $icon-login;
  }
}
.icon-user-add {
  &:before {
    content: $icon-user-add;
  }
}
.icon-user-checkmark {
  &:before {
    content: $icon-user-checkmark;
  }
}
.icon-credit-card {
  &:before {
    content: $icon-credit-card;
  }
}
.icon-vimeo {
  &:before {
    content: $icon-vimeo;
  }
}
.icon-conversation-chat {
  &:before {
    content: $icon-conversation-chat;
  }
}
.icon-document-folder {
  &:before {
    content: $icon-document-folder;
  }
}
.icon-book-library {
  &:before {
    content: $icon-book-library;
  }
}
.icon-book-close-bookmark {
  &:before {
    content: $icon-book-close-bookmark;
  }
}
.icon-safety-float {
  &:before {
    content: $icon-safety-float;
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard;
  }
}
.icon-add-box {
  &:before {
    content: $icon-add-box;
  }
}
.icon-add {
  &:before {
    content: $icon-add;
  }
}
.icon-alert-triangle {
  &:before {
    content: $icon-alert-triangle;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-check-box {
  &:before {
    content: $icon-check-box;
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down;
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up;
  }
}
.icon-clear {
  &:before {
    content: $icon-clear;
  }
}
.icon-cloud-upload {
  &:before {
    content: $icon-cloud-upload;
  }
}
.icon-comment {
  &:before {
    content: $icon-comment;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-email-add {
  &:before {
    content: $icon-email-add;
  }
}
.icon-email-body {
  &:before {
    content: $icon-email-body;
  }
}
.icon-email {
  &:before {
    content: $icon-email;
  }
}
.icon-event-busy {
  &:before {
    content: $icon-event-busy;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-first-page {
  &:before {
    content: $icon-first-page;
  }
}
.icon-flag {
  &:before {
    content: $icon-flag;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-keyboard-arrow-down {
  &:before {
    content: $icon-keyboard-arrow-down;
  }
}
.icon-keyboard-arrow-left {
  &:before {
    content: $icon-keyboard-arrow-left;
  }
}
.icon-keyboard-arrow-next {
  &:before {
    content: $icon-keyboard-arrow-next;
  }
}
.icon-keyboard-arrow-previous {
  &:before {
    content: $icon-keyboard-arrow-previous;
  }
}
.icon-keyboard-arrow-right {
  &:before {
    content: $icon-keyboard-arrow-right;
  }
}
.icon-keyboard-arrow-up {
  &:before {
    content: $icon-keyboard-arrow-up;
  }
}
.icon-last-page {
  &:before {
    content: $icon-last-page;
  }
}
.icon-logout {
  &:before {
    content: $icon-logout;
  }
}
.icon-maps {
  &:before {
    content: $icon-maps;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-more-horizontal {
  &:before {
    content: $icon-more-horizontal;
  }
}
.icon-more-vert {
  &:before {
    content: $icon-more-vert;
  }
}
.icon-navigation-menu-horizontal {
  &:before {
    content: $icon-navigation-menu-horizontal;
  }
}
.icon-navigation-menu-vertical {
  &:before {
    content: $icon-navigation-menu-vertical;
  }
}
.icon-person-outline {
  &:before {
    content: $icon-person-outline;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
  }
}
.icon-question-circle {
  &:before {
    content: $icon-question-circle;
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh;
  }
}
.icon-school {
  &:before {
    content: $icon-school;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-wifi-5m {
  &:before {
    content: $icon-wifi-5m;
  }
}
.icon-wifi-30s {
  &:before {
    content: $icon-wifi-30s;
  }
}
.icon-wifi-off {
  &:before {
    content: $icon-wifi-off;
  }
}
.icon-wifi-refresh {
  &:before {
    content: $icon-wifi-refresh;
  }
}
.icon-wifi {
  &:before {
    content: $icon-wifi;
  }
}
.icon-send-email {
  &:before {
    content: $icon-send-email;
  }
}
.icon-settings-off {
  &:before {
    content: $icon-settings-off;
  }
}
.icon-settings-on {
  &:before {
    content: $icon-settings-on;
  }
}
.icon-sms {
  &:before {
    content: $icon-sms;
  }
}
.icon-stopwatch {
  &:before {
    content: $icon-stopwatch;
  }
}
.icon-video-call {
  &:before {
    content: $icon-video-call;
  }
}
.icon-view {
  &:before {
    content: $icon-view;
  }
}
.icon-wifi-1m {
  &:before {
    content: $icon-wifi-1m;
  }
}
.icon-transfer-inline {
  &:before {
    content: $icon-transfer-inline;
  }
}
.icon-transfer {
  &:before {
    content: $icon-transfer;
  }
}
.icon-undo-half {
  &:before {
    content: $icon-undo-half;
  }
}
.icon-undo-full {
  &:before {
    content: $icon-undo-full;
  }
}
.icon-undo-dotted {
  &:before {
    content: $icon-undo-dotted;
  }
}
.icon-undo {
  &:before {
    content: $icon-undo;
  }
}
.icon-heart {
  &:before {
    content: $icon-heart;
  }
}
.icon-hourglass {
  &:before {
    content: $icon-hourglass;
  }
}
.icon-ice-cream-bite {
  &:before {
    content: $icon-ice-cream-bite;
  }
}
.icon-ice-cream {
  &:before {
    content: $icon-ice-cream;
  }
}
.icon-megaphone {
  &:before {
    content: $icon-megaphone;
  }
}
.icon-signal {
  &:before {
    content: $icon-signal;
  }
}
.icon-weather-cloud {
  &:before {
    content: $icon-weather-cloud;
  }
}
.icon-wheat {
  &:before {
    content: $icon-wheat;
  }
}
.icon-pin-location {
  &:before {
    content: $icon-pin-location;
  }
}
.icon-time-clock-circle {
  &:before {
    content: $icon-time-clock-circle;
  }
}

.icon-hash {
  &:before {
    content: $icon-hash;
  }
}
.icon-course {
  &:before {
    content: $icon-course;
  }
}

