@import "variables";

@font-face {
  font-family: '#{$icomoon-bold-font-family}';
  src:  url('~#{$icomoon-bold-font-path}/#{$icomoon-bold-font-family}.eot?{version}');
  src:  url('~#{$icomoon-bold-font-path}/#{$icomoon-bold-font-family}.eot?{version}#iefix') format('embedded-opentype'),
    url('~#{$icomoon-bold-font-path}/#{$icomoon-bold-font-family}.ttf?{version}') format('truetype'),
    url('~#{$icomoon-bold-font-path}/#{$icomoon-bold-font-family}.woff?{version}') format('woff'),
    url('~#{$icomoon-bold-font-path}/#{$icomoon-bold-font-family}.svg?{version}##{$icomoon-bold-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.etrainu-icon-bold {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-bold-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
  .etrainu-icon-bold.icon-safety-float {
    &:before {
      content: $icon-safety-float;
    }
  }
  .etrainu-icon-bold.icon-training-library {
    &:before {
      content: $icon-training-library;
    }
  }
  .etrainu-icon-bold.icon-book-close-bookmark {
    &:before {
      content: $icon-book-close-bookmark;
    }
  }
  .etrainu-icon-bold.icon-dashboard {
    &:before {
      content: $icon-dashboard;
    }
  }
  .etrainu-icon-bold.icon-arrow-right {
    &:before {
      content: $icon-arrow-right;
    }
  }
  .etrainu-icon-bold.icon-add-alarm {
    &:before {
      content: $icon-add-alarm;
    }
  }
  .etrainu-icon-bold.icon-add-box {
    &:before {
      content: $icon-add-box;
    }
  }
  .etrainu-icon-bold.icon-add {
    &:before {
      content: $icon-add;
    }
  }
  .etrainu-icon-bold.icon-alert-triangle {
    &:before {
      content: $icon-alert-triangle;
    }
  }
  .etrainu-icon-bold.icon-arrow-down {
    &:before {
      content: $icon-arrow-down;
    }
  }
  .etrainu-icon-bold.icon-arrow-left {
    &:before {
      content: $icon-arrow-left;
    }
  }
  .etrainu-icon-bold.icon-arrow-up {
    &:before {
      content: $icon-arrow-up;
    }
  }
  .etrainu-icon-bold.icon-calendar {
    &:before {
      content: $icon-calendar;
    }
  }
  .etrainu-icon-bold.icon-check-box-alternate {
    &:before {
      content: $icon-check-box-alternate;
    }
  }
  .etrainu-icon-bold.icon-check-box-blank {
    &:before {
      content: $icon-check-box-blank;
    }
  }
  .etrainu-icon-bold.icon-check-box-outline-blank {
    &:before {
      content: $icon-check-box-outline-blank;
    }
  }
  .etrainu-icon-bold.icon-check-box {
    &:before {
      content: $icon-check-box;
    }
  }
  .etrainu-icon-bold.icon-chevron-down {
    &:before {
      content: $icon-chevron-down;
    }
  }
  .etrainu-icon-bold.icon-chevron-left {
    &:before {
      content: $icon-chevron-left;
    }
  }
  .etrainu-icon-bold.icon-chevron-right {
    &:before {
      content: $icon-chevron-right;
    }
  }
  .etrainu-icon-bold.icon-chevron-up {
    &:before {
      content: $icon-chevron-up;
    }
  }
  .etrainu-icon-bold.icon-clear-solid {
    &:before {
      content: $icon-clear-solid;
    }
  }
  .etrainu-icon-bold.icon-clear {
    &:before {
      content: $icon-clear;
    }
  }
  .etrainu-icon-bold.icon-close {
    &:before {
      content: $icon-close;
    }
  }
  .etrainu-icon-bold.icon-cloud-upload {
    &:before {
      content: $icon-cloud-upload;
    }
  }
  .etrainu-icon-bold.icon-comment {
    &:before {
      content: $icon-comment;
    }
  }
  .etrainu-icon-bold.icon-edit {
    &:before {
      content: $icon-edit;
    }
  }
  .etrainu-icon-bold.icon-email-add {
    &:before {
      content: $icon-email-add;
    }
  }
  .etrainu-icon-bold.icon-email-body {
    &:before {
      content: $icon-email-body;
    }
  }
  .etrainu-icon-bold.icon-email {
    &:before {
      content: $icon-email;
    }
  }
  .etrainu-icon-bold.icon-event-busy {
    &:before {
      content: $icon-event-busy;
    }
  }
  .etrainu-icon-bold.icon-event {
    &:before {
      content: $icon-event;
    }
  }
  .etrainu-icon-bold.icon-filter {
    &:before {
      content: $icon-filter;
    }
  }
  .etrainu-icon-bold.icon-first-page {
    &:before {
      content: $icon-first-page;
    }
  }
  .etrainu-icon-bold.icon-flag {
    &:before {
      content: $icon-flag;
    }
  }
  .etrainu-icon-bold.icon-home {
    &:before {
      content: $icon-home;
    }
  }
  .etrainu-icon-bold.icon-keyboard-arrow-down {
    &:before {
      content: $icon-keyboard-arrow-down;
    }
  }
  .etrainu-icon-bold.icon-keyboard-arrow-left {
    &:before {
      content: $icon-keyboard-arrow-left;
    }
  }
  .etrainu-icon-bold.icon-keyboard-arrow-next {
    &:before {
      content: $icon-keyboard-arrow-next;
    }
  }
  .etrainu-icon-bold.icon-keyboard-arrow-previous {
    &:before {
      content: $icon-keyboard-arrow-previous;
    }
  }
  .etrainu-icon-bold.icon-keyboard-arrow-right {
    &:before {
      content: $icon-keyboard-arrow-right;
    }
  }
  .etrainu-icon-bold.icon-keyboard-arrow-up {
    &:before {
      content: $icon-keyboard-arrow-up;
    }
  }
  .etrainu-icon-bold.icon-last-page {
    &:before {
      content: $icon-last-page;
    }
  }
  .etrainu-icon-bold.icon-logout {
    &:before {
      content: $icon-logout;
    }
  }
  .etrainu-icon-bold.icon-maps {
    &:before {
      content: $icon-maps;
    }
  }
  .etrainu-icon-bold.icon-menu {
    &:before {
      content: $icon-menu;
    }
  }
  .etrainu-icon-bold.icon-more-horizontal {
    &:before {
      content: $icon-more-horizontal;
    }
  }
  .etrainu-icon-bold.icon-more-vert {
    &:before {
      content: $icon-more-vert;
    }
  }
  .etrainu-icon-bold.icon-person-outline {
    &:before {
      content: $icon-person-outline;
    }
  }
  .etrainu-icon-bold.icon-phone {
    &:before {
      content: $icon-phone;
    }
  }
  .etrainu-icon-bold.icon-question-circle {
    &:before {
      content: $icon-question-circle;
    }
  }
  .etrainu-icon-bold.icon-refresh {
    &:before {
      content: $icon-refresh;
    }
  }
  .etrainu-icon-bold.icon-school {
    &:before {
      content: $icon-school;
    }
  }
  .etrainu-icon-bold.icon-search {
    &:before {
      content: $icon-search;
    }
  }
  .etrainu-icon-bold.icon-send-email {
    &:before {
      content: $icon-send-email;
    }
  }
  .etrainu-icon-bold.icon-settings-off {
    &:before {
      content: $icon-settings-off;
    }
  }
  .etrainu-icon-bold.icon-settings-on {
    &:before {
      content: $icon-settings-on;
    }
  }
  .etrainu-icon-bold.icon-sms {
    &:before {
      content: $icon-sms;
    }
  }
  .etrainu-icon-bold.icon-stopwatch {
    &:before {
      content: $icon-stopwatch;
    }
  }
  .etrainu-icon-bold.icon-video-call {
    &:before {
      content: $icon-video-call;
    }
  }
  .etrainu-icon-bold.icon-view {
    &:before {
      content: $icon-view;
    }
  }
  .etrainu-icon-bold.icon-wifi-1m {
    &:before {
      content: $icon-wifi-1m;
    }
  }
  .etrainu-icon-bold.icon-wifi-5m {
    &:before {
      content: $icon-wifi-5m;
    }
  }
  .etrainu-icon-bold.icon-wifi-30s {
    &:before {
      content: $icon-wifi-30s;
    }
  }
  .etrainu-icon-bold.icon-wifi-off {
    &:before {
      content: $icon-wifi-off;
    }
  }
  .etrainu-icon-bold.icon-wifi-refresh {
    &:before {
      content: $icon-wifi-refresh;
    }
  }
  .etrainu-icon-bold.icon-wifi {
    &:before {
      content: $icon-wifi;
    }
  }
  .etrainu-icon-bold.icon-validation-check {
    &:before {
      content: $icon-validation-check;
    }
  }
  .etrainu-icon-bold.icon-check-circle {
    &:before {
      content: $icon-check-circle;
    }
  }
  .etrainu-icon-bold.icon-no-entry {
    &:before {
      content: $icon-no-entry;
    }
  }
