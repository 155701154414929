@import "../../../../assets/foundation-sites/settings";
@import "../../../../mixins";
@include tabs;
:host {
  .page-container {
    &.inner-tabs {
      position: relative;
      z-index: 7;
    }
    .event-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        font-size: rem-calc(28px);
        line-height: rem-calc(32px);
        margin-bottom: 0;
      }
      .event-account {
        font-size: rem-calc(16px);
        display: flex;
        align-items: center;
        padding-top: rem-calc(8px);
        mat-icon {
          padding-right: .4rem;
          box-sizing: content-box;
          font-size: rem-calc(18px);
          width: rem-calc(18px);
          height: rem-calc(18px);
        }
      }
    }


    fa {
      margin-right: 10px;
      font-size: 18px;
      color: #E12C31;
    }

    @include event-info
  }

  .tab-page {
    padding: rem-calc(15px) 0;
  }
  .page-actions {
    margin-bottom: 1rem;
  }
  .refresh-button {
    &.is-reloading {
      mat-icon {
        animation: start-spinning 1s 0s 1 ease-out, infinite-spinning 1s 1s infinite ease-out;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .event-page {
      .event-tabs {
        .tab {
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    h1 {
      font-size: rem-calc(38px);
    }
  }
}
