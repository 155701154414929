@import "@angular/material/prebuilt-themes/indigo-pink.css";

@import "assets/foundation-sites/settings";
@import "./assets/foundation-sites/typography";
@import '../node_modules/bootstrap/scss/bootstrap-reboot';
@import '../node_modules/bootstrap/scss/bootstrap-grid';
@import '../node_modules/bootstrap/scss/utilities/text';

/* You can add global styles to this file, and also import other style files */
@import '../node_modules/foundation-sites/scss/foundation';
@import "../node_modules/foundation-sites/assets/foundation";
@include foundation-everything(true);

// Foundation Components
@import '../node_modules/foundation-sites/scss/grid/grid';
@import '../node_modules/foundation-sites/scss/typography/typography';
@import '../node_modules/foundation-sites/scss/forms/forms';
@import '../node_modules/foundation-sites/scss/components/visibility';
@import '../node_modules/foundation-sites/scss/components/float';
@import '../node_modules/foundation-sites/scss/components/button';
@import '../node_modules/foundation-sites/scss/components/button-group';
@import '../node_modules/foundation-sites/scss/components/accordion-menu';
@import '../node_modules/foundation-sites/scss/components/accordion';
@import '../node_modules/foundation-sites/scss/components/badge';
@import '../node_modules/foundation-sites/scss/components/breadcrumbs';
@import '../node_modules/foundation-sites/scss/components/callout';
@import '../node_modules/foundation-sites/scss/components/card';
@import '../node_modules/foundation-sites/scss/components/close-button';
@import '../node_modules/foundation-sites/scss/components/drilldown';
@import '../node_modules/foundation-sites/scss/components/dropdown-menu';
@import '../node_modules/foundation-sites/scss/components/dropdown';
@import '../node_modules/foundation-sites/scss/components/flex';
@import '../node_modules/foundation-sites/scss/components/responsive-embed';
@import '../node_modules/foundation-sites/scss/components/label';
@import '../node_modules/foundation-sites/scss/components/media-object';
@import '../node_modules/foundation-sites/scss/components/menu';
@import '../node_modules/foundation-sites/scss/components/menu-icon';
@import '../node_modules/foundation-sites/scss/components/off-canvas';
@import '../node_modules/foundation-sites/scss/components/orbit';
@import '../node_modules/foundation-sites/scss/components/pagination';
@import '../node_modules/foundation-sites/scss/components/progress-bar';
@import '../node_modules/foundation-sites/scss/components/reveal';
@import '../node_modules/foundation-sites/scss/components/slider';
@import '../node_modules/foundation-sites/scss/components/sticky';
@import '../node_modules/foundation-sites/scss/components/switch';
@import '../node_modules/foundation-sites/scss/components/table';
@import '../node_modules/foundation-sites/scss/components/tabs';
@import '../node_modules/foundation-sites/scss/components/title-bar';
@import '../node_modules/foundation-sites/scss/components/top-bar';
@import '../node_modules/foundation-sites/scss/components/thumbnail';
@import '../node_modules/foundation-sites/scss/components/tooltip';


@import 'app/pages/events/event/event.component.scss';
@import "assets/fonts/etrainu-ui-icons/etrainu-ui-icons";
@import "assets/fonts/etrainu-icons-regular/etrainu-icon-regular";
@import "assets/fonts/etrainu-icons-bold/etrainu-icon-bold";
@import "assets/fonts/etrainu-icons-thin/etrainu-icon-thin";
@import "mixins";
@import "animations";

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
:root {
  --app-height: 100vh;
}
/* You can add global styles to this file, and also import other style files */
html {
  transform: translate3d(0,0,0);
}
.iPhoneX {
  padding-top: constant(safe-area-inset-top);//for iOS 11.2
  padding-top: env(safe-area-inset-top);//for iOS 11.1
}
html, body {
  overflow-x: hidden;
  height: 100vh;
  height: var(--app-height);
  width: 100vw;
  overflow: hidden;
}
* {
  font-family: 'Montserrat', sans-serif;
  -webkit-overflow-scrolling: auto;

  h1 {
    font-family: 'Montserrat', sans-serif !important;
  }

  p {
    font-size: rem-calc(14px);
    margin-bottom: 0.5rem;
  }
}

.mat-icon{
  font-size: 1.4rem;
  &.icon-search{
    font-size: 20px;
  }
}

.page-container {
  padding: 0 rem-calc(15px);
  -webkit-transform: translate3d(0, 0, 0);
  background-color: #ffffff;
  /*max-height: 100vh;*/
  overflow: auto;
  -webkit-overflow-scrolling: touch !important;
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: 0;
  background: center center no-repeat;
  background-size: cover;
  &.inner-nav {
    z-index: 4;
  }
  & > * {
    flex-shrink: 0;
  }
    .tab-page {
      flex-grow: 1;
    }

  h1 {
    font-weight: 700;
    font-size: 28px;
  }

  h6 {
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 2px;
  }
}
[class*=col-] {
  box-sizing: border-box;
}
.otb-button-o {
  color: $accent;
  font-weight: 600;
  border: 1px solid $accent;
  padding: 10px 15px;
  border-radius: 20px;
}

.otb-snack {
  margin-left: auto !important;
  margin-right: auto !important;
  color: white;

  simple-snack-bar {
    span {
      width: 100%;
      text-align: center;
      font-weight: 600;
      font-size: 15px;
    }
  }
  .mat-simple-snackbar-action {
    color: white;
    span {
      text-decoration: underline;
    }
  }
  &.success {
    background-color: #0762A8;
  }

  &.error {
    background-color: #E12C31;
  }
}

.feature-snack {
  background-color: #0762A8;

  button {
    color: #FFD52C;

    &:hover, &:active, &:focus {
      outline: none;
    }
  }
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px 0;
  border-radius: 5px !important;
  border: 1px solid #f0f0f8;
  .input-icon {
    padding-left: 8px;
    padding-right: 8px;
    color: #E12C31;
    margin-left: 10px;
  }
  .search-input {
    box-shadow: none;
    background-color: transparent;
    color: #232425;
    height: auto;
    border: none;
    outline: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    @extend .body-1;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}
.loading {
  width: rem-calc(24px);
  height: rem-calc(24px);
  position: relative;
  &.hidden {
    display: none;
  }
}
.cell.auto {
  width: auto;
  flex: auto;
  flex-shrink: 1;
  flex-grow: 0;
  font-size: 0;
}
@media screen and (max-width: 767px) {
  /*.cdk-overlay-pane {
    width: 90vw !important;
  }*/
  .otb-dialog {
    width: 100vw !important;
    height: 100vh !important;
    height: var(--app-height) !important;
    max-height: none !important;
    .mat-dialog-container {
      padding: 1.5rem !important;
      max-height: 100vh;
      box-sizing: border-box;
    }
    .mat-dialog-content {
      max-height: calc(100vh - 48px) !important;
    }
  }
}
@media screen and (min-width: 737px) {
  .page-container {
    /*margin-left: 65px;*/
  }
}
.no-padding {
  padding: 0 !important;
}
::ng-deep {
  .ngx-datatable {
      /*-webkit-overflow-scrolling: auto;*/
      -webkit-transform: translate3d(0, 0, 0);
      -webkit-backface-visibility: hidden;
      -webkit-perspective: 1000;
  }
}
/**:not(html) {
  -webkit-transform: translate3d(0, 0, 0);
}*/

.flex-spacer {
  flex-grow: 1;
}



.button {
  &:focus {
    outline: 0;
  }
  &.rounded {
    overflow: hidden;
  }
  &.small {
    padding: .25rem 1rem;
    font-size: 1rem;
    & > *, mat-icon {
      height: 1rem;
      width: 1rem;
      line-height: 1rem;
      vertical-align: middle;
      font-size: 1rem;
    }
  }
  &.icon-button {
    padding: 0 rem-calc(10px);
    margin-bottom: 0;
    &.small {
      font-size: 0;
      padding: .25rem
    }
  }
  & > *, mat-icon {
    height: 1rem;
    line-height: 1rem;
    vertical-align: middle;
    display: block;
  }
  &.hollow {
    border: 2px solid;
  }

  &.fab {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: rem-calc(48px);
    height: rem-calc(48px);
    font-size: rem-calc(36px);
    padding: 0;
    margin-top: 0;
    z-index: 4;
    & > * {
      height: 2.6rem;
      width: 2.6rem;
      line-height: 2.6rem;
      font-size: 1.5rem;
      background: none;
      display: inline-block;
    }
  }
  &.submit-button {
    position: relative;
    & > .hidden {
      visibility: hidden;
    }
    .loading {
      height: 1.5rem;
      width: 1.5rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      top: 50%;
      .loader {
        stroke-width: 0.5rem;
        position: relative;
      }
    }
  }
}
.loader {
  width: 99%;
  height: 99%;
  transform: rotate(-90deg);
  stroke-linecap: round;
  stroke-width: 4;
  fill: none;
  position: absolute;

  .external-circle {
    stroke: #fff;
    stroke-dashoffset: 0;
    transform-origin: center;
  }

  .external-circle {
    stroke-dasharray: 312;
    animation: external 1s linear infinite;
    opacity: .9;
  }
}
[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
  height: auto;
}
.checkbox {
  & > * {
    vertical-align: middle;
  }
}
.disabled {
  opacity: 0.5;
  cursor: no-drop !important;
}
.cell[class*="offset-auto"] {
  margin-left: auto;
  margin-right: auto;
}

.upload-document-mat-menu button {
  border-radius: unset;
}
