@import "variables";

@font-face {
  font-family: '#{$icomoon-thin-font-family}';
  src:  url('~#{$icomoon-thin-font-path}/#{$icomoon-thin-font-family}.eot?{version}');
  src:  url('~#{$icomoon-thin-font-path}/#{$icomoon-thin-font-family}.eot?{version}#iefix') format('embedded-opentype'),
    url('~#{$icomoon-thin-font-path}/#{$icomoon-thin-font-family}.ttf?{version}') format('truetype'),
    url('~#{$icomoon-thin-font-path}/#{$icomoon-thin-font-family}.woff?{version}') format('woff'),
    url('~#{$icomoon-thin-font-path}/#{$icomoon-thin-font-family}.svg?{version}##{$icomoon-thin-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.etrainu-icon-thin {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-thin-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

    &.icon-single-man {
  &:before {
    content: $icon-thin-single-man;}
}
    &.icon-lock {
  &:before {
    content: $icon-thin-lock; 
  }
}
    &.icon-add-alarm {
  &:before {
    content: $icon-thin-add-alarm;}
}
    &.icon-add-box {
  &:before {
    content: $icon-thin-add-box;}
}
    &.icon-add {
  &:before {
    content: $icon-thin-add;}
}
    &.icon-alert-triangle {
  &:before {
    content: $icon-thin-alert-triangle;}
}
    &.icon-arrow-down {
  &:before {
    content: $icon-thin-arrow-down;}
}
    &.icon-arrow-left {
  &:before {
    content: $icon-thin-arrow-left;}
}
    &.icon-arrow-right {
  &:before {
    content: $icon-thin-arrow-right;}
}
    &.icon-arrow-up {
  &:before {
    content: $icon-thin-arrow-up;}
}
    &.icon-calendar {
  &:before {
    content: $icon-thin-calendar;}
}
    &.icon-check-box-outline-blank {
  &:before {
    content: $icon-thin-check-box-outline-blank;}
}
    &.icon-check-box {
  &:before {
    content: $icon-thin-check-box;}
}
    &.icon-chevron-down {
  &:before {
    content: $icon-thin-chevron-down;}
}
    &.icon-chevron-left {
  &:before {
    content: $icon-thin-chevron-left;}
}
    &.icon-chevron-right {
  &:before {
    content: $icon-thin-chevron-right;}
}
    &.icon-chevron-up {
  &:before {
    content: $icon-thin-chevron-up;}
}
    &.icon-clear {
  &:before {
    content: $icon-thin-clear;}
}
    &.icon-close {
  &:before {
    content: $icon-thin-close;}
}
    &.icon-cloud-upload {
  &:before {
    content: $icon-thin-cloud-upload;}
}
    &.icon-edit {
  &:before {
    content: $icon-thin-edit;}
}
    &.icon-email-add {
  &:before {
    content: $icon-thin-email-add;}
}
    &.icon-email-body {
  &:before {
    content: $icon-thin-email-body;}
}
    &.icon-email {
  &:before {
    content: $icon-thin-email;}
}
    &.icon-event-busy {
  &:before {
    content: $icon-thin-event-busy;}
}
    &.icon-events-thin {
  &:before {
    content: $icon-thin-events-thin; 
  }
}
    &.icon-filter {
  &:before {
    content: $icon-thin-filter;}
}
    &.icon-first-page {
  &:before {
    content: $icon-thin-first-page;}
}
    &.icon-flag {
  &:before {
    content: $icon-thin-flag;}
}
    &.icon-help-circle-thin {
  &:before {
    content: $icon-thin-help-circle-thin; 
  }
}
    &.icon-home-thin {
  &:before {
    content: $icon-thin-home-thin; 
  }
}
    &.icon-last-page {
  &:before {
    content: $icon-thin-last-page;}
}
    &.icon-logout {
  &:before {
    content: $icon-thin-logout;}
}
    &.icon-maps {
  &:before {
    content: $icon-thin-maps;}
}
    &.icon-menu {
  &:before {
    content: $icon-thin-menu;}
}
    &.icon-more-horizontal {
  &:before {
    content: $icon-thin-more-horizontal;}
}
    &.icon-more-vert {
  &:before {
    content: $icon-thin-more-vert;}
}
    &.icon-person-outline {
  &:before {
    content: $icon-thin-person-outline;}
}
    &.icon-phone {
  &:before {
    content: $icon-thin-phone;}
}
    &.icon-refresh {
  &:before {
    content: $icon-thin-refresh;}
}
    &.icon-school {
  &:before {
    content: $icon-thin-school;}
}
    &.icon-search {
  &:before {
    content: $icon-thin-search;}
}
    &.icon-settings-off {
  &:before {
    content: $icon-thin-settings-off;}
}
    &.icon-settings-on {
  &:before {
    content: $icon-thin-settings-on;}
}
    &.icon-sms {
  &:before {
    content: $icon-thin-sms;}
}
    &.icon-video-call-alt {
  &:before {
    content: $icon-thin-video-call-alt; 
  }
}
    &.icon-video-call {
  &:before {
    content: $icon-thin-video-call;}
}
    &.icon-view {
  &:before {
    content: $icon-thin-view;}
}
    &.icon-wifi-1m {
  &:before {
    content: $icon-thin-wifi-1m;}
}
    &.icon-wifi-5m {
  &:before {
    content: $icon-thin-wifi-5m;}
}
    &.icon-wifi-30s {
  &:before {
    content: $icon-thin-wifi-30s;}
}
    &.icon-wifi-off {
  &:before {
    content: $icon-thin-wifi-off;}
}
    &.icon-wifi-refresh {
  &:before {
    content: $icon-thin-wifi-refresh;}
}
    &.icon-wifi {
  &:before {
    content: $icon-thin-wifi;}
}

}